import { Backdrop, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  GetResourceTypeQuery,
  GetResourceWithTypeQuery,
  Kind,
  ParameterizedResource,
  useGetResourcesLazyQuery,
  useGetResourceTypeQuery,
  useGetResourceWithTypeQuery,
} from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import {
  saveComponent,
  saveComponentToLibrary,
  unlinkComponentFromLibrary,
} from "../../utils/forms/resource-manager";
import { MinimumRequiredConfig } from "../PipelineGraph/PipelineGraph";
import { useV2PipelineGraph } from "../PipelineGraphV2/PipelineGraphV2Context";
import { FormValues } from "../ResourceConfigForm";
import { DialogResource } from "../ResourceDialog";
import { EditResourceDialog } from "../ResourceDialog/EditResourceDialog";

// TODO: move to shared-queries?
type ResourceType =
  GetResourceWithTypeQuery["resourceWithType"]["resourceType"] &
    GetResourceTypeQuery["resourceType"];
type Resource = GetResourceWithTypeQuery["resourceWithType"]["resource"];

export interface ConnectorDialogProps {
  configuration?: MinimumRequiredConfig;
  readOnly?: boolean;
}

export const ConnectorDialog: React.FC<ConnectorDialogProps> = ({
  configuration,
  readOnly,
}) => {
  const {
    editingConnector,
    editConnectorOpen,
    closeConnectorDialog,
    refetchConfiguration,
  } = useV2PipelineGraph();

  const { enqueueSnackbar } = useSnackbar();

  const name = editingConnector?.name ?? "";
  const typeName = editingConnector?.type ?? "";

  const [resource, setResource] = useState<Resource | null>(null);
  const [resourceType, setResourceType] = useState<ResourceType | null>(null);

  const [libraryResources, setLibraryResources] = useState<DialogResource[]>();

  useGetResourceWithTypeQuery({
    variables: { name, kind: Kind.Connector },
    skip: editingConnector == null || editingConnector.isInline(),
    onCompleted: ({ resourceWithType }) => {
      const { resource, resourceType } = resourceWithType;
      setResource(resource);
      setResourceType(resourceType);
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("Failed to fetch ConnectorType", {
        variant: "error",
      });
    },
  });

  useGetResourceTypeQuery({
    variables: { name: typeName, kind: Kind.ConnectorType },
    skip: editingConnector == null || !editingConnector.isInline(),
    onCompleted: ({ resourceType }) => {
      setResourceType(resourceType);
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("Failed to fetch ConnectorType", {
        variant: "error",
      });
    },
  });

  const [fetchLibraryResources] = useGetResourcesLazyQuery({
    fetchPolicy: "network-only",
    variables: { kind: Kind.Connector },
    onCompleted: (data) => {
      setLibraryResources(
        data.resources.map((r) => ({
          metadata: {
            name: r.metadata.name,
          },
          spec: r.spec,
        })),
      );
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("Failed to fetch Library Connectors", {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (editConnectorOpen && editingConnector != null) {
      setLibraryResources(undefined);
      fetchLibraryResources();
    }
  }, [editConnectorOpen, editingConnector, fetchLibraryResources]);

  async function onAddToLibrary(formValues: FormValues, name: string) {
    await saveComponentToLibrary({
      configuration,
      component: editingConnector!,
      kind: Kind.Connector,
      formValues,
      name,
      enqueueSnackbar,
      onSuccess: ({ reference }) => {
        closeConnectorDialog();
        refetchConfiguration();
      },
    });
  }

  async function onUnlinkFromLibrary(formValues: FormValues, name: string) {
    await unlinkComponentFromLibrary({
      configuration,
      component: editingConnector!,
      resource: resource!,
      kind: Kind.Connector,
      formValues,
      name,
      enqueueSnackbar,
      onSuccess: ({ inline }) => {
        closeConnectorDialog();
        refetchConfiguration();
      },
    });
  }

  function onClose() {
    setResource(null);
    setResourceType(null);
    setLibraryResources(undefined);
    closeConnectorDialog();
  }

  function onSave(formValues: FormValues) {
    saveComponent({
      configuration,
      component: editingConnector!,
      kind: Kind.Connector,
      formValues,
      enqueueSnackbar,
      onSuccess: ({ updated }) => {
        closeConnectorDialog();
        refetchConfiguration();
      },
    });
  }

  if (
    editingConnector == null ||
    resourceType == null ||
    libraryResources == null
  ) {
    if (editConnectorOpen) {
      return (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
    return null;
  }

  const commonProps = {
    resourceType,
    component: editingConnector,
    readOnly,
    open: editConnectorOpen,
    onSave,
    onDelete: undefined,
    onClose,
    onCancel: onClose,
    onAddToLibrary,
    onUnlinkFromLibrary,
    libraryResources,
  };

  if (editingConnector.isInline()) {
    return <InlineConnectorDialog {...commonProps} kind={Kind.Connector} />;
  } else if (resource != null) {
    return (
      <LibraryConnectorDialog
        {...commonProps}
        kind={Kind.Connector}
        resource={resource!}
      />
    );
  }
  return null;
};

// ----------------------------------------------------------------------
interface BaseConnectorDialogProps {
  kind: Kind.Source | Kind.Destination | Kind.Processor | Kind.Connector;
  resourceType: ResourceType;
  readOnly?: boolean;
  open: boolean;

  // handlers
  onSave: (values: { [key: string]: any }) => void;
  onDelete?: () => void;
  onCancel: () => void;
  onClose: () => void;

  // pause
  paused?: boolean;
  onTogglePause?: () => void;

  // library
  onAddToLibrary?: (values: { [key: string]: any }, name: string) => void;
  onUnlinkFromLibrary?: (values: { [key: string]: any }, name: string) => void;
  libraryResources?: DialogResource[];
}

// ----------------------------------------------------------------------

interface InlineConnectorDialogProps extends BaseConnectorDialogProps {
  component: BPResourceConfiguration;
}

const InlineConnectorDialog: React.FC<InlineConnectorDialogProps> = ({
  kind,
  resourceType,
  component,
  readOnly,
  open,
  onSave,
  onDelete,
  onClose,
  paused,
  onTogglePause,
  onAddToLibrary,
  onUnlinkFromLibrary,
  libraryResources,
}) => {
  return (
    <EditResourceDialog
      kind={kind}
      displayName={component.displayName ?? ""}
      resourceTypeDisplayName={resourceType?.metadata.displayName ?? ""}
      description={resourceType?.metadata.description ?? ""}
      additionalInfo={resourceType?.metadata.additionalInfo}
      resourceDocLink={resourceType?.metadata.resourceDocLink ?? ""}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onClose}
      parameters={component.parameters ?? []}
      parameterDefinitions={resourceType?.spec.parameters ?? []}
      open={open}
      onClose={onClose}
      stability={resourceType?.metadata.stability ?? undefined}
      readOnly={readOnly}
      paused={paused}
      onTogglePause={onTogglePause}
      showLibraryBookmark={
        onAddToLibrary != null && onUnlinkFromLibrary != null
      }
      onAddToLibrary={onAddToLibrary}
      onUnlinkFromLibrary={onUnlinkFromLibrary}
      libraryResources={libraryResources}
    />
  );
};

// ----------------------------------------------------------------------
interface LibraryConnectorDialogProps extends BaseConnectorDialogProps {
  component: BPResourceConfiguration;
  resource: ParameterizedResource;
}

const LibraryConnectorDialog: React.FC<LibraryConnectorDialogProps> = ({
  kind,
  resourceType,
  component,
  resource,
  readOnly,
  open,
  onSave,
  onDelete,
  onClose,
  paused,
  onTogglePause,
  onAddToLibrary,
  onUnlinkFromLibrary,
  libraryResources,
}) => {
  return (
    <EditResourceDialog
      kind={kind}
      displayName={resource.metadata.displayName ?? ""}
      resourceTypeDisplayName={resourceType?.metadata.displayName ?? ""}
      resourceNameField={resource.metadata.name ?? ""}
      description={resourceType?.metadata.description ?? ""}
      additionalInfo={resourceType?.metadata.additionalInfo}
      resourceDocLink={resourceType?.metadata.resourceDocLink ?? ""}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onClose}
      parameters={resource.spec.parameters ?? []}
      parameterDefinitions={resourceType?.spec.parameters ?? []}
      open={open}
      onClose={onClose}
      stability={resourceType?.metadata.stability ?? undefined}
      readOnly={readOnly}
      paused={paused}
      onTogglePause={onTogglePause}
      showLibraryBookmark={
        onAddToLibrary != null && onUnlinkFromLibrary != null
      }
      onAddToLibrary={onAddToLibrary}
      onUnlinkFromLibrary={onUnlinkFromLibrary}
      libraryResources={libraryResources}
    />
  );
};
