import { enqueueSnackbar } from "notistack";
import { createContext, useContext, useState } from "react";
import {
  ResourceConfiguration,
  usePipelineGraphV2MetricsSubscription,
} from "../../graphql/generated";
import { BPConfiguration, BPResourceConfiguration } from "../../utils/classes";
import { findResource } from "../../utils/classes/configuration";
import { BPGraph } from "../ConfigurationFlowV2/graph";
import { BPGraphMetrics } from "../ConfigurationFlowV2/metrics";
import { DEFAULT_TELEMETRY_TYPE } from "../MeasurementControlBar/MeasurementControlBar";
import {
  initialData,
  PipelineGraphMetricsData,
} from "./PipelineGraphMetricsData";
import { AttributeName, V2Config } from "./types";

export interface PipelineGraphContextValue {
  configuration: V2Config;
  graph?: BPGraph;
  metrics?: BPGraphMetrics;

  refetchConfiguration: () => void;

  selectedTelemetryType: string;
  period: string;

  hoveredSet: string[];
  setHoveredSet: React.Dispatch<React.SetStateAction<string[]>>;

  editProcessorsInfo: EditProcessorsInfo | null;
  // editProcessor opens up the editing dialog for a source or destination
  editProcessors: (componentPath: string) => void;
  closeProcessorDialog(): void;
  editProcessorsOpen: boolean;

  editingConnector: BPResourceConfiguration | null;
  editConnector: (componentPath: string) => void;
  closeConnectorDialog: () => void;
  editConnectorOpen: boolean;

  metricData: PipelineGraphMetricsData;

  readOnlyGraph?: boolean;

  addSourceOpen: boolean;
  addDestinationOpen: boolean;
  setAddSourceOpen: (value: boolean) => void;
  setAddDestinationOpen: (value: boolean) => void;

  agentID?: string;
}

export interface PipelineGraphProviderProps {
  configuration: V2Config;
  refetchConfiguration: () => void;
  selectedTelemetryType: string;
  period: string;
  readOnly?: boolean;
  addSourceOpen: boolean;
  addDestinationOpen: boolean;
  setAddSourceOpen: (value: boolean) => void;
  setAddDestinationOpen: (value: boolean) => void;
  agentID?: string;
}

export interface EditProcessorsInfo {
  componentPath: string;
  snapshotID: string;
  component: ResourceConfiguration;
}

const defaultValue: PipelineGraphContextValue = {
  configuration: null,
  graph: undefined,
  metrics: undefined,
  refetchConfiguration: () => {},
  selectedTelemetryType: DEFAULT_TELEMETRY_TYPE,
  period: "1m",
  hoveredSet: [],
  setHoveredSet: () => {},
  editProcessors: () => {},
  closeProcessorDialog: () => {},
  editProcessorsInfo: null,
  editProcessorsOpen: false,
  editingConnector: null,
  editConnector: () => {},
  closeConnectorDialog: () => {},
  editConnectorOpen: false,
  metricData: initialData,
  setAddSourceOpen: () => {},
  setAddDestinationOpen: () => {},
  addSourceOpen: false,
  addDestinationOpen: false,
  agentID: undefined,
};

export const V2PipelineContext = createContext(defaultValue);

export const V2PipelineGraphProvider: React.FC<PipelineGraphProviderProps> = ({
  children,
  selectedTelemetryType,
  period,
  configuration,
  refetchConfiguration,
  setAddSourceOpen,
  setAddDestinationOpen,
  addSourceOpen,
  addDestinationOpen,
  readOnly,
  agentID,
}) => {
  const [hoveredSet, setHoveredSet] = useState<string[]>([]);
  const [metricData, setMetricData] =
    useState<PipelineGraphMetricsData>(initialData);
  const [editProcessorsInfo, setEditingProcessors] =
    useState<EditProcessorsInfo | null>(null);
  const [editProcessorsOpen, setEditProcessorsOpen] = useState(false);

  const [editingConnector, setEditingConnector] =
    useState<BPResourceConfiguration | null>(null);
  const [editConnectorOpen, setEditConnectorOpen] = useState(false);

  const graph = new BPGraph(configuration?.graph);
  const metrics = new BPGraphMetrics(
    graph,
    metricData,
    selectedTelemetryType,
    period,
  );

  function editProcessors(componentPath: string) {
    const component = findResource(configuration!, componentPath);
    if (!component) {
      throw new Error(`Could not find component with path ${componentPath}`);
    }

    const snapshotID = getSnapshotID(graph, componentPath);
    if (!snapshotID) {
      throw new Error(
        `Could not find snapshot ID for component with path ${componentPath}`,
      );
    }
    setEditingProcessors({ component, componentPath, snapshotID });
    setEditProcessorsOpen(true);
  }

  function closeProcessorDialog() {
    setEditProcessorsOpen(false);
    // Reset the editing processors on a timeout to avoid a flash of empty state.
    setTimeout(() => {
      setEditingProcessors(null);
    }, 300);
  }

  function editConnector(componentPath: string) {
    const config = new BPConfiguration(configuration);
    const component = config.findResource(componentPath);
    if (component == null) {
      enqueueSnackbar(`Component ${componentPath} not found in Configuration`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    setEditingConnector(component);
    setEditConnectorOpen(true);
  }

  function closeConnectorDialog() {
    setEditConnectorOpen(false);
    // Reset the connecting component on a timeout to avoid a flash of empty state.
    setTimeout(() => {
      setEditingConnector(null);
    }, 300);
  }

  usePipelineGraphV2MetricsSubscription({
    variables: {
      period: period,
      name: configuration?.metadata.name,
      agent: agentID,
    },
    onSubscriptionData(opts) {
      if (opts.subscriptionData.data) {
        setMetricData(new PipelineGraphMetricsData(opts.subscriptionData.data));
      }
    },
  });

  return (
    <V2PipelineContext.Provider
      value={{
        configuration,
        graph,
        metrics,
        refetchConfiguration,
        setHoveredSet,
        hoveredSet,
        selectedTelemetryType,
        editProcessors,
        closeProcessorDialog,
        editProcessorsInfo,
        editProcessorsOpen,
        editingConnector,
        editConnector,
        editConnectorOpen,
        closeConnectorDialog,
        readOnlyGraph: readOnly,
        setAddSourceOpen,
        setAddDestinationOpen,
        addSourceOpen,
        addDestinationOpen,
        agentID,
        metricData,
        period,
      }}
    >
      {children}
    </V2PipelineContext.Provider>
  );
};

export function getSnapshotID(
  graph: BPGraph,
  componentPath: string,
): string | null {
  const node = graph.findNodes(
    (n) =>
      n.attributes[AttributeName.ComponentPath] === componentPath &&
      n.attributes[AttributeName.SnapshotID],
  )?.[0];

  if (node) {
    return node.attributes[AttributeName.SnapshotID];
  }

  return null;
}

export function useV2PipelineGraph(): PipelineGraphContextValue {
  return useContext(V2PipelineContext);
}
