import { BPConfiguration } from "../../../utils/classes";
import { wrapResource } from "../../../utils/classes/resource";
import { RoutingConnectorCard } from "../../Cards/RoutingConnectorCard";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";

export function RoutingConnectorNodeV2({
  data,
}: {
  data: {
    id: string;
    metric: string;
    attributes: Record<string, any>;
  };
}) {
  const { id, attributes } = data;
  const { configuration, editConnector } = useV2PipelineGraph();

  const { componentPath } = attributes;
  const c = new BPConfiguration(configuration!);
  const resource = c.findResource(componentPath);

  const nodeResource = wrapResource(attributes["resource"]);

  return (
    <>
      <RoutingConnectorCard
        nodeId={id}
        onClick={() =>
          resource && editConnector(resource.componentPath("connectors"))
        }
        resource={resource}
        nodeResource={nodeResource}
      />
    </>
  );
}
